// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adgroup-js": () => import("./../../../src/pages/adgroup.js" /* webpackChunkName: "component---src-pages-adgroup-js" */),
  "component---src-pages-asin-js": () => import("./../../../src/pages/asin.js" /* webpackChunkName: "component---src-pages-asin-js" */),
  "component---src-pages-campaign-js": () => import("./../../../src/pages/campaign.js" /* webpackChunkName: "component---src-pages-campaign-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keyword-js": () => import("./../../../src/pages/keyword.js" /* webpackChunkName: "component---src-pages-keyword-js" */),
  "component---src-pages-keyword-negative-campaign-js": () => import("./../../../src/pages/keyword_negative_campaign.js" /* webpackChunkName: "component---src-pages-keyword-negative-campaign-js" */),
  "component---src-pages-keyword-negative-js": () => import("./../../../src/pages/keyword_negative.js" /* webpackChunkName: "component---src-pages-keyword-negative-js" */),
  "component---src-pages-place-cm-js": () => import("./../../../src/pages/placeCm.js" /* webpackChunkName: "component---src-pages-place-cm-js" */),
  "component---src-pages-productad-js": () => import("./../../../src/pages/productad.js" /* webpackChunkName: "component---src-pages-productad-js" */),
  "component---src-pages-productgroup-js": () => import("./../../../src/pages/productgroup.js" /* webpackChunkName: "component---src-pages-productgroup-js" */),
  "component---src-pages-statistics-js": () => import("./../../../src/pages/statistics.js" /* webpackChunkName: "component---src-pages-statistics-js" */),
  "component---src-pages-target-js": () => import("./../../../src/pages/target.js" /* webpackChunkName: "component---src-pages-target-js" */),
  "component---src-pages-target-negative-js": () => import("./../../../src/pages/target_negative.js" /* webpackChunkName: "component---src-pages-target-negative-js" */)
}

