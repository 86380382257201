import React,{createContext,useReducer} from "react"

const initState = {
    isMenuOpen:false,
    isTableMenuFixed:false,
    isTableSubMenuFixed:false,

    showAddNewModal:false,
    showBulkActionModal:false,

    showTBModal:false,
    updateMethodTB:null,
    TBModalOriginalData:{},
    TBModal:'',

    customRange:'',
    customRangePlaceholder:'',
    monthRange:'',
    dateRange:'',
    stateFilter:'',
    searchText:'',

    // Settings
    fontFamily:'raleway',
    fontSize:'',
    showChart:true,
    rowsPerPageMenu:null,

    // Active Market
    // activeMarket:"DE-304373076738316",

    // Account Config
    accountConfig:{},

    // User Config
    userConfig:{},
    userMarkets:{},

    activeRulesSearchFilter:null,
}

const store = createContext(initState)
const {Provider} = store

const StateProvider = ({children}) => {
    const [state,dispatch] = useReducer((state,action) => {
        switch(action.type){
            // case 'CHANGE_MARKET':
            //     return{
            //         ...state,
            //         activeMarket:action.payload
            //     }
            case 'SET_ACTIVE_FILTER':
                return{
                    ...state,
                    activeRulesSearchFilter:action.payload
                }
            case 'SET_ACCOUNT_CONFIG':
                return{
                    ...state,
                    accountConfig:action.payload
                }
            case 'SET_USER_CONFIG':
                return{
                    ...state,
                    userConfig:action.payload.user,
                    userMarkets:action.payload.markets
                }
            case 'TOGGLE_MENU':
                return{
                    ...state,
                    isMenuOpen:!state.isMenuOpen
                }
            case 'TOGGLE_TABLE_MENU':
                return{
                    ...state,
                    isTableMenuFixed: action.payload !== undefined ? action.payload:!state.isTableMenuFixed
                }
            case 'CHANGE_TABLE_FONT_FAMILY':
                return{
                    ...state,
                    fontFamily:action.payload,
                }
            case 'CHANGE_TABLE_FONT_SIZE':
                return{
                    ...state,
                    fontSize:action.payload,
                }
            case 'TOGGLE_TABLE_SUB_MENU':
                return{
                    ...state,
                    isTableSubMenuFixed: action.payload !== undefined ? action.payload:!state.isTableSubMenuFixed
                }
            case 'TOGGLE_ADD_NEW_MODAL':
                return{
                    ...state,
                    showAddNewModal:action.payload !== undefined ? action.payload : !state.showAddNewModal
                }
            case 'TOGGLE_TB_MODAL':
                return{
                    ...state,
                    showTBModal:action.payload?.show !== undefined ? action.payload?.show : !state.showTBModal,
                    updateMethodTB:action.payload?.updateMethod,
                    TBModalOriginalData:action.payload?.data,
                    TBModal:action.payload?.modal
                }
            case 'TOGGLE_BULK_ACTION_MODAL':
                return{
                    ...state,
                    showBulkActionModal:action.payload !== undefined ? action.payload : !state.showBulkActionModal
                }       
            case 'TOGGLE_CHART_DISPLAY':
                return{
                    ...state,
                    showChart:!state.showChart
                }       
            case 'UPDATE_ROWS_PER_PAGE_MENU':
                return{
                    ...state,
                    rowsPerPageMenu:action.payload
                }
            case 'UPDATE_SEARCH_FILTERS':
                return{
                    ...state,
                    customRange:
                        action.payload.customRange === undefined ? 
                            state.customRange : action.payload.customRange
                        ,
                    // customRangePlaceholder:
                    //     action.payload.customRangePlaceholder === undefined ?
                    //         state.customRangePlaceholder : action.payload.customRangePlaceholder
                    //     ,
                    monthRange:
                        action.payload.monthRange === undefined ?
                            state.monthRange : action.payload.monthRange
                        ,
                    // monthRangePlaceholder:
                    //     action.payload.monthRangePlaceholder === undefined ?
                    //         state.monthRangePlaceholder : action.payload.monthRangePlaceholder
                    //     ,
                    dateRange:
                        action.payload.dateRange === undefined ?
                            state.dateRange : action.payload.dateRange
                        ,
                    stateFilter:
                        action.payload.stateFilter === undefined ?
                            state.stateFilter : action.payload.stateFilter
                        ,
                    searchText:
                        action.payload.searchText === undefined ?
                            state.searchText : action.payload.searchText
                        ,
                }

            default:
                throw new Error()    
        }
    },initState)

    return <Provider value={{state,dispatch}}>{children}</Provider>
}

export {store,StateProvider}