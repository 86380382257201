// export const API_URL = "https://www1.zon.tools/api/dashboard/";

export const API_URL = "https://www1.zon.tools/DE-304373076738316/dashboard/ajax.php?headers=bee";
export const ActiveMarket = "DE-304373076738316"
export const ActiveMarketName = "DE-Huididi"

// export const API_URL = "https://www1.zon.tools/sandbox/dashboard/ajax.php?headers=bee";
// export const ActiveMarket = "sandbox"
// export const ActiveMarketName = "API Sandbox Account"


export const API_TOKEN = '3f60153595c16767a7187d0e82eecd54d00e24d8c619db902fcd56c868b6e216&headers=all';

// Account Config
// https://www1.zon.tools/DE-304373076738316/dashboard/ajax.php?page=all&account_config=read

// https://www1.zon.tools/api/announcement/tips.php

export const API_SMART_NAVIGATION = "https://www1.zon.tools/sandbox/dashboard/smart_navigation.php?headers=bee"
export const API_NOTIFICATIONS = "https://www1.zon.tools/api/announcement"
// export const API_URL_ADGROUP = `${API_URL}adgroup.php?token=${API_TOKEN}`;
// export const API_URL_KEYWORD = `${API_URL}keyword.php?token=${API_TOKEN}`;

// &intervalCustom=thisYear&intervalMonth=&dateStart=&dateEnd=&stateFilter=&searchText=&paginate=
// headers = 
// all
// localhost
// audit

// LIVE DEMO
// http://lite.codedthemes.com/datta-able/react/default/basic/badges

export const MONTHS = {
    '01':"Jan",
    '02':"Feb",
    '03':"Mar",
    '04':"Apr",
    '05':"May",
    '06':"Jun",
    '07':"Jul",
    '08':"Aug",
    '09':"Sep",
    '10':"Oct",
    '11':"Nov",
    '12':"Dec",
}

export const market = "DE"//getMarketPlace() !== undefined ? getMarketPlace().split("-")[0]:""

export const moneyObj = {
    "DE":"€",
    "US":"$",
    "UK":"£",
    "JP":"¥",
    "IN":"₹",
    "AE":"Dh",
    "AU":"$",
    "CA":"C$",
    "ES":"€",
    "FR":"€",
    "IT":"€",
    "MX":"$",
}