import React,{useContext,useEffect,useState} from "react"
import {store,StateProvider} from "src/context/store"

import {API_URL} from "src/constants/constants"

import "src/scss/main.scss"
import "./static/fonts/fonts.css"

import '/node_modules/font-awesome/scss/font-awesome.scss';

const AccountConfig = ({children}) => {
    const [marketCur,setMarketCur] = useState("$")

    const globalState = useContext(store)
    const {dispatch} = globalState

    useEffect(() => {
        // console.log("We've started!")
        fetch(`${API_URL}&page=all&user_config=read`,{
            method:"GET",
            credentials:'include'
        })
        .then(res => res.json())
        .then(response => {
            let marketObj = {}
            Object.keys(response.result).map(key => {
                if(key.includes("marketplaces")){
                    marketObj[key] = response.result[key]
                }
            })

            dispatch({type:"SET_USER_CONFIG",payload:
                {
                    "user":response.result,
                    "markets":marketObj
                }
            })

        }).catch(err => console.log(err))


        fetch(`${API_URL}&page=all&account_config=read`,{
            method:"GET",
            credentials:'include'
        })
        .then(res => res.json())
        .then(response => {
            setMarketCur(response.result.marketplaceCur)
            dispatch({type:"SET_ACCOUNT_CONFIG",payload:response.result})
        }).catch(err => console.log(err))
    },[])

    return(
        <React.Fragment>
            <span style={{display:'none'}} data-marketplacecur={marketCur} />
            {children}
        </React.Fragment>
    )
}

export const wrapRootElement = ({element}) => (
    <StateProvider>
        <AccountConfig>
            {element}
        </AccountConfig>
    </StateProvider>
)

// export const onClientEntry = () => {
//     console.log("We've started!")
//     fetch(`${API_URL}&page=all&account_config=read`)
//     .then(res => res.json())
//     .then(response => console.log(response))
// }